/**
 * B&Y APPLICATION FRONTEND
 * (c) 2019 B&Y
 */

/**
 * JUPITER IMPORTS
 */
import {
  Application,
  Cookies,
  Events,
  Links,
  MobileMenu,
  Moonwalk,
  FixedHeader,
  Dom
} from '@univers-agency/jupiter'

/**
 * APP SPECIFIC MODULE IMPORTS
 */

/**
 * CONFIG IMPORTS
 */
import configureBreakpoints from './config/BREAKPOINTS'
import configureMoonwalk from './config/MOONWALK'


import '../css/app.pcss'

const app = new Application({
  breakpointConfig: configureBreakpoints
})

app.registerCallback(Events.APPLICATION_READY, () => {
  app.links = new Links(app)
})

app.registerCallback(Events.APPLICATION_PRELUDIUM, () => {
  app.moonwalk = new Moonwalk(app, configureMoonwalk(app))
})

// trigger ready state
if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
  app.initialize()
} else {
  document.addEventListener('DOMContentLoaded', app.initialize.apply(app))
}

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
  module.hot.accept()
}
