export default () => ({
  breakpoints: [
    'iphone',
    'mobile',
    'ipad_portrait',
    'ipad_landscape',
    'desktop_md',
    'desktop_lg',
    'desktop_xl'
  ],
  runListenerOnInit: false
})
