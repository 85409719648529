import { gsap, Dom } from '@univers-agency/jupiter'

export default app => ({
  rootMargin: '-10%',
  threshold: 0,
  initialDelay: 0.1,

  runs: {
    heroIllustration: {
      threshold: 0,
      callback: heroIllustration => {
        const image = Dom.find(heroIllustration, 'img')
        gsap.set(heroIllustration, { xPercent: 100 })
        gsap.set(image, { opacity: 0 })
        gsap.set(heroIllustration, { visibility: 'visible' })
        gsap.to(heroIllustration, { duration: 0.75, xPercent: 0, ease: 'power2.out' })
        gsap.to(image, {
          duration: 2, opacity: 1, delay: 0.65
        })
      }
    },

    heroContent: {
      threshold: 0,
      callback: heroContent => {
        let delay = 1
        if (app.breakpoints.mediaQueries.iphone.matches
          || app.breakpoints.mediaQueries.mobile.matches
          || app.breakpoints.mediaQueries.ipad_portrait.matches) {
          delay = 0
        }
        const walks = Dom.all(heroContent, '[data-moonwalk]')
        gsap.set(walks, { autoAlpha: 0, y: 20 })
        gsap.set(heroContent, { visibility: 'visible' })
        gsap.to(walks, {
          delay, duration: 1, autoAlpha: 1, y: 0, stagger: '0.3'
        })
      }
    }
  },

  walks: {
    default: {
      interval: 0.1, // was 0.03
      duration: 0.75,
      alphaTween: true,
      transition: {
        from: {
          y: 10
        },
        to: {
          ease: 'sine.out',
          force3D: true,
          y: 0
        }
      }
    },

    // stage
    resourceBackground: {
      interval: 0.5, // was 0.03
      duration: 0.75,
      alphaTween: true,
      sectionTargets: '.bg, .resource',

      transition: {
        from: {
          y: 125
        },
        to: {
          y: 0,
          ease: 'power3.out'
        }
      }
    },

    resourcePage: {
      interval: 0,
      duration: 0.75,

      transition: {
        from: {
          y: 200
        },
        to: {
          delay: 1.25,
          y: 0,
          ease: 'power3.out'
        }
      }
    },

    heroBg: {
      interval: 0.1, // was 0.03
      duration: 0.85,
      transition: {
        from: {
          autoAlpha: 0
        },
        to: {
          autoAlpha: 1,
          ease: 'sine.out'
        }
      }
    },

    heroText: {
      interval: 0.3, // was 0.03
      duration: 0.85,
      alphaTween: true,
      startDelay: 0.45,
      transition: {
        from: {
          y: 35
        },
        to: {
          y: 0,
          ease: 'power3.out'
        }
      }
    },

    fast: {
      duration: 0.2,
      interval: 0.07,
      transition: {
        from: {
          x: 8,
          autoAlpha: 0
        },

        to: {
          x: 0,
          autoAlpha: 1
        }
      }
    },

    slider: {
      sectionTargets: '.glide-slide',
      interval: 0.2,
      duration: 1.2,
      alphaTween: true,
      transition: {
        from: {
          autoAlpha: 0,
          y: 21
        },
        to: {
          ease: 'sine.out',
          y: 0
        }
      }
    }
  }
})
